<template>
	<div>
		<div v-if="total > 0">
			<div class="listItem" v-for="(item, index) of dataList" :key="index">
				<el-row class="contentpanel">
					<div class="title ellipsisContent">
						<router-link
							:to="'/CommunicationPlatform/' + item.learnTopicID"
							class="entity entity-title"
							>{{ item.title || "" }}</router-link
						>
					</div>
					<div class="content ellipsisContent">
						{{ item.content }}
					</div>
				</el-row>
				<el-row class="operationRow">
					<button class="delButton" @click="deleteTotpic(item.learnTopicID)">
						删除
					</button>
				</el-row>
			</div>
			<div class="pagination" v-if="total > 0">
				<el-pagination
					id="pagination"
					:current-page="pageIndex"
					:page-size="pageSize"
					:page-sizes="[10, 25, 50]"
					layout="prev, pager, next, jumper, sizes"
					:total="total"
					@current-change="queryChange"
					@size-change="sizeChange"
					@prev-click="queryChange"
					@next-click="queryChange"
				>
				</el-pagination>
			</div>
			<div v-if="showConfirm" style="width: 100%; height: 100%">
				<ConfirmDialog
					title="话题删除"
					describeText="确认删除该话题"
					imgPath="delete.png"
					cancelButtonText="取消删除"
					confirmButtonText="确认删除"
					:confirmVal="confirmObj"
					@cancel="cancelDelete"
					@confirm="confirmDelete"
				></ConfirmDialog>
			</div>
		</div>
		<div v-else class="blank">暂未发布话题</div>
	</div>
</template>

<script>
import { ApiClient, UserCenterApi } from "../../../api"
import ConfirmDialog from "../../../components/ContirmDialog.vue"
export default {
	data() {
		return {
			dataList: [],
			total: 0,
			pageIndex: 1,
			pageSize: 10,
			api: new UserCenterApi(ApiClient.instance),
			showConfirm: false,
			confirmObj: null
		}
	},
	components: {
		ConfirmDialog
	},
	created() {
		this.getData()
	},
	methods: {
		getData() {
			this.api.apiUserCenterMylearntopicGet(
				0,
				{ index: this.pageIndex, size: this.pageSize },
				(err, data, resp) => {
					if (err) {
						console.log(err)
					}
					if (data && data.result) {
						this.dataList = data.result.records
						this.total = data.result.total
						console.log(this.dataList)
					}
				}
			)
		},
		// 分页条件改变
		queryChange(val) {
			this.pageIndex = val
			this.getData()
		},
		sizeChange(val) {
			this.pageIndex = 1
			this.pageSize = val
			this.getData()
		},
		deleteTotpic(id) {
			this.confirmObj = id
			this.showConfirm = true
		},
		cancelDelete(val) {
			this.showConfirm = false
		},
		confirmDelete(val) {
			this.showConfirm = false
			this.api.apiUserCenterTopicsetstatusPost(val, 3, (err, data, resp) => {
				if (err) {
					console.log(err)
				}
				if (data && data.result) {
					this.$message({ type: "success", message: "发布话题删除成功！" })
				} else {
					this.$message({ type: "error", message: "发布话题删除失败" })
				}
				if (this.total - 1 > 0) {
					if (this.pageIndex > ((this.total - 1) / this.pageSize)) {
						this.pageIndex = Math.ceil((this.total - 1) / this.pageSize)
					}
				} else {
					this.pageIndex = 1
				}
				this.getData()
			})
		}
	}
}
</script>

<style lang="less" scoped>
.el-pagination {
	margin-top: 10px;
}
.blank{
	text-align: center;
	margin-top: 200px;
}
.listItem {
	margin-top: 20px;
	.contentpanel {
		background: #ffffff;
		box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
		opacity: 1;
		border-radius: 0px;
		padding: 16px 22px;

		.title {
			width: 100%;
			font-size: 18px;
			line-height: 25px;
			font-weight: 500;
			color: #3462e9;
			-webkit-line-clamp: 2;
		}

		.content {
			margin-top: 10px;
			width: 100%;
			font-size: 14px;
			font-weight: 400;
			color: #333333;
			-webkit-line-clamp: 3;
		}
	}
	.operationRow {
		width: 100%;
		padding: 11px 15px 10px 0;
		background: #bbd4ff;
		border-radius: 0px;

		button {
			border: none;
			float: right;
		}

		.delButton {
			cursor: pointer;
			width: 54px;
			height: 24px;
			background: #ffffff;
			border-radius: 3px;
			font-size: 12px;
			font-weight: 400;
			color: #666666;
		}
	}
}
</style>
